import { createApp } from 'vue'
import App from './App.vue'

// Router
import router from './router'

// Vuex
import store from './store'

// Element Plus样式
import 'element-plus/dist/index.css'

// 全局样式
import './styles/index.css'

const app = createApp(App)

// Use
app.use(store)
app.use(router)

app.mount('#app')
