import { createRouter, createWebHistory } from 'vue-router'
import { ElMessage } from 'element-plus'
import { isUserLoggedIn, setIsLoggedIn, setUserinfo, setToken, removeIsLoggedIn, removeUserinfo, removeToken } from '@/libs/auth'
import { getUserinfo } from '@/apis/user'

const routes = [
  {
    path: '/',
    name: 'index-index',
    component: () => import(/* webpackChunkName: "index-index" */ '@/views/index/index-index/IndexIndexView.vue'),
    meta: {
      resource: 'Index',
      action: 'read',
      layout: 'full',
      isLoggedIn: false
    }
  },
  {
    path: '/login',
    name: 'login-index',
    component: () => import(/* webpackChunkName: "login-index" */ '@/views/login/login-index/LoginIndexView.vue'),
    meta: {
      resource: 'Login',
      action: 'read',
      layout: 'full',
      isLoggedIn: false
    }
  },
  {
    path: '/register',
    name: 'register-index',
    component: () => import(/* webpackChunkName: "register-index" */ '@/views/register/register-index/RegisterIndexView.vue'),
    meta: {
      resource: 'Register',
      action: 'read',
      layout: 'full',
      isLoggedIn: false
    }
  },
  {
    path: '/query',
    name: 'query-index',
    component: () => import(/* webpackChunkName: "query-index" */ '@/views/query/query-index/QueryIndexView.vue'),
    meta: {
      resource: 'Query',
      action: 'read',
      layout: 'full',
      isLoggedIn: false
    }
  },
  {
    path: '/vessel',
    name: 'vessel-index',
    component: () => import(/* webpackChunkName: "vessel-index" */ '@/views/vessel/vessel-index/VesselIndexView.vue'),
    meta: {
      resource: 'Vessel',
      action: 'read',
      layout: 'full',
      isLoggedIn: false
    }
  },
  {
    path: '/vessel/map',
    name: 'vessel-map',
    component: () => import(/* webpackChunkName: "vessel-map" */ '@/views/vessel/vessel-map/VesselMapView.vue'),
    meta: {
      resource: 'Vessel',
      action: 'read',
      layout: 'full',
      isLoggedIn: false,
      menuActive: 'vessel-index'
    }
  },
  {
    path: '/truck',
    name: 'truck-index',
    component: () => import(/* webpackChunkName: "truck-index" */ '@/views/truck/truck-index/TruckIndexView.vue'),
    meta: {
      resource: 'Truck',
      action: 'read',
      layout: 'full',
      isLoggedIn: false
    }
  },
  {
    path: '/common/iframe',
    name: 'common-iframe',
    component: () => import(/* webpackChunkName: "common-iframe" */ '@/views/common/common-iframe/CommonIframeView.vue'),
    meta: {
      resource: 'Common',
      action: 'iframe',
      layout: 'full',
      isLoggedIn: false
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    return { left: 0, top: 0 }
  },
  routes
})

router.beforeEach((to, from, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!isLoggedIn) {
    if (to.meta.isLoggedIn) {
      removeIsLoggedIn();
      removeUserinfo();
      removeToken();
      ElMessage({
        message: '请登录后操作',
        type: 'error'
      });
      return next({ name: 'login-index' });
    }
  } else {
    getUserinfo({
      custom: {
        redirect: false,
      },
    }).then(res => {
      setIsLoggedIn(true);
      setUserinfo(res);
      setToken(res.token);
    });

    if (['login-index', 'register-index'].indexOf(to.name) !== -1) {
      ElMessage({
        message: '你已登录，无需重复登录',
        type: 'success'
      });
      return next({ name: 'index-index' });
    }
  }

  next();
})

router.afterEach((to, from) => {
  // ...
})

export default router
