import { createStore } from 'vuex'

import user from './user'

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user
  },
  strict: process.env.DEV,
})
