import axios from 'axios'
import router from '@/router'
import { ElMessage } from 'element-plus'
import 'element-plus/es/components/message/style/css'
import { getToken, removeIsLoggedIn, removeUserinfo, removeToken } from '@/libs/auth'

const instance = axios.create({
    baseURL: process.env.VUE_APP_REQUEST_BASEURL,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Device-Type': 'web'
    },
    custom: {
        toast: true,
        catch: true,
        auth: true
    }
});

instance.interceptors.request.use((config) => {
    config.data = config.data || {}
    if (config?.custom?.auth) {
        const accessToken = getToken();

        if (accessToken) {
            config.headers['Authorization'] = accessToken;
        }
    }

    return config;
}, (error) => {
    const custom = error.config?.custom;

    if (custom?.toast !== false) {
        ElMessage({
            message: '网络崩溃了',
            type: 'error'
        })
    }

    return Promise.reject(error);
})

instance.interceptors.response.use((response) => {
    const data = response.data
    const custom = response.config?.custom

    if (response.status !== 200) {
        if (custom?.toast !== false) {
            ElMessage({
                message: data.error || '未知参数',
                type: 'error'
            })
        }

        if (custom?.catch) {
            return Promise.reject(data);
        } else {
            return new Promise(() => { });
        }
    }

    // return data.data === undefined ? data : data.data
    return data
}, (error) => {
    const custom = error.response?.config?.custom;
    const data = error.response?.data;
    const status = error.response?.status;

    if (custom?.toast !== false) {
        switch (status) {
            case 401:
                removeIsLoggedIn();
                removeUserinfo();
                removeToken();
                ElMessage({
                    message: data?.error || '请登录后操作',
                    type: 'error'
                })
                router.push({ name: 'login-index' });
                break;
            case 403:
                ElMessage({
                    message: data?.error || '没有权限',
                    type: 'error'
                })
                break;
            case 404:
                ElMessage({
                    message: data?.error || '错误链接',
                    type: 'error'
                })
                break;
            case 422:
                ElMessage({
                    message: data?.error || '参数错误',
                    type: 'error'
                })
                break;
            case 500:
                ElMessage({
                    message: data?.error || '服务器错误',
                    type: 'error'
                })
                break;
            case 504:
                ElMessage({
                    message: data?.error || '网关超时',
                    type: 'error'
                })
                break;
            default:
                ElMessage({
                    message: data?.error || '网络崩溃了',
                    type: 'error'
                })
                break;
        }
    }

    return Promise.reject(error)
})

export default instance
