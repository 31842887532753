export default {
    namespaced: true,
    state: {
        isLoggedIn: false,
        userinfo: null,
        token: null
    },
    getters: {
        getIsLoggedIn: state => state.isLoggedIn,
        getUserinfo: state => state.userinfo,
        getToken: state => state.token
    },
    mutations: {
        setIsLoggedIn: (state, data) => state.isLoggedIn = data,
        setUserinfo: (state, data) => state.userinfo = data,
        setToken: (state, data) => state.token = data
    },
    actions: {
        setIsLoggedIn: (context, payload) => context.commit('setIsLoggedIn', payload),
        setUserinfo: (context, payload) => context.commit('setUserinfo', payload),
        setToken: (context, payload) => context.commit('setToken', payload)
    },
}