<template>
  <el-config-provider :locale="locale" size="large">
    <component :is="layout">
      <router-view></router-view>
    </component>
  </el-config-provider>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { ElConfigProvider } from "element-plus";
import zhCn from "element-plus/lib/locale/lang/zh-cn";

const LayoutFull = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "layout-full" */ "@/layouts/layout-full/LayoutFull.vue"
  )
);

export default {
  components: {
    ElConfigProvider,
    LayoutFull,
  },
  setup() {
    return {
      locale: zhCn,
    };
  },
  computed: {
    layout() {
      if (this.$route.meta.layout === "full") return "layout-full";
      return `layout-${this.contentLayoutType}`;
    },
    contentLayoutType() {
      return "horizontal";
    },
  },
};
</script>

