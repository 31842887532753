import store from '@/store'

export const isUserLoggedIn = () => {
    return localStorage.getItem('isLoggedIn');
}

export const getIsLoggedIn = () => JSON.parse(localStorage.getItem('isLoggedIn'));

export const getUserinfo = () => JSON.parse(localStorage.getItem('userinfo'));

export const getToken = () => localStorage.getItem('token');

export const setIsLoggedIn = (isLoggedIn) => {
    localStorage.setItem('isLoggedIn', JSON.stringify(isLoggedIn));
    store.dispatch('user/setIsLoggedIn', isLoggedIn);
}

export const setUserinfo = (userinfo) => {
    localStorage.setItem('userinfo', JSON.stringify(userinfo));
    store.dispatch('user/setUserinfo', userinfo);
}

export const setToken = (token) => {
    localStorage.setItem('token', token);
    store.dispatch('user/setToken', token);
}

export const removeIsLoggedIn = () => {
    localStorage.removeItem('isLoggedIn');
    store.dispatch('user/setIsLoggedIn', false);
}

export const removeUserinfo = () => {
    localStorage.removeItem('userinfo');
    store.dispatch('user/setUserinfo', null);
}

export const removeToken = () => {
    localStorage.removeItem('token');
    store.dispatch('user/setToken', null);
}
